import { useTheme } from "@emotion/react";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import messenger from "assets/images/global/messenger.png";
import { lazy } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router";
import { useLocation } from "react-router-dom";
import { RootState } from "store";
import { global_transalations } from "transalations";
import Categories from "ui-components/Categories";
import Loadable from "ui-components/Loadable";
import { IsAuthenticatedRoute, set_localize_content } from "utils/helper";
import { getDataFromLocalStorage } from "utils/localStore";
import Header from "./Header";

const Footer = Loadable(lazy(() => import("./Footer")));
const BottomNav = Loadable(lazy(() => import("./navBarBottom")));

export default function MainLayout({ classes }: { classes?: any }) {
  const location = useLocation();
  const theme = useTheme() as any;
  const store = getDataFromLocalStorage("store");
  const matchesDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const language = useSelector(
    (state: RootState) => state.language.language
  ) as any;

  const webMessengerLink =
    "http://m.me/dominosgeorgia?hash=(null)&source_id=6946816";
  const mobileMessengerLink = "https://m.me/dominosgeorgia?ref=chat";
  const handleClick = (event: any) => {
    event.preventDefault();
    if (/Mobi|Android|iPhone/i.test(navigator.userAgent)) {
      window.location.href = mobileMessengerLink;
    } else {
      window.open(webMessengerLink, "_blank");
    }
  };

  return (
    <>
      <Box sx={{ minHeight: matchesDownMd ? "" : "80vh" }}>
        <Header classes={classes} />

        {!IsAuthenticatedRoute() && location.pathname !== "/" && store ? (
          <Categories />
        ) : null}

        <Outlet />
      </Box>

      {matchesDownMd && !IsAuthenticatedRoute() && store ? (
        <BottomNav classes={classes} />
      ) : null}

      {!matchesDownMd || IsAuthenticatedRoute() ? <Footer /> : null}

      <div
        style={{
          position: "fixed",
          bottom: matchesDownMd ? 70 : 70,
          right: matchesDownMd ? 4 : 20,
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <a
              href={
                /Mobi|Android|iPhone/i.test(navigator.userAgent)
                  ? mobileMessengerLink
                  : webMessengerLink
              }
              onClick={handleClick}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={messenger}
                height={matchesDownMd ? 40 : 60}
                width={matchesDownMd ? 40 : 60}
                alt="messenger"
                loading="lazy"
              />
            </a>
          </div>
          <p style={{ fontSize: matchesDownMd ? 12 : 16 }}>
            {set_localize_content(global_transalations.live_support, language)}
          </p>
        </div>
      </div>
    </>
  );
}
