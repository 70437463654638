export const defaultLabelColors = {
  color_e: "#181818",
  color_f: "#666666",
  color_g: "#eeeeee",
  color_h: "#f8f8f8",
};

export const GOOGLE_MAPS_APIKEY = "AIzaSyDqDgbiEfC-6TPUP2l4RpHgq3KI4NQmEE4";
export const BIRTHDAY_COUPON_CODE = "_BD101"
export const NEW_CUS_COUPON_CODE = "_NC101"
export const MAX_FREE_TOPPINGS = 5;
export const MIN_CART_PRICE = 16.9