import { useUserSettings } from "hooks/useUserSettings";
import MainLayout from "layout/MainLayout";
import {
  About,
  AddAddress,
  AddCard,
  AddressDetails,
  Addressess,
  Bread,
  Cart,
  Chicken,
  ChooseStore,
  DeliveryPolicy,
  Desserts,
  Drinks,
  Favorite,
  Feedback,
ForgotPassword,
  HalfnHalfDetails,
  Home,
  HygieneCertificate,
  JoinOurTeam,
  JoinUsForm,
  LoadPotato,
  Login,
  NotFound,
  OfferDetail,
  Offers,
  OrderConfirmation,
  OrderDetail,
  OrderHistory,
  OTPVerification,
  PhoneVerify,
  Pizza,
  PizzaDetails,
  PrivacyPolicy,
  ReturnPolicy,
  Rewards,
  Sandwich,
  Sauce,
  ServiceMethod,
  SignUpDetails,
  SingleOrderDetails,
  Stores,
  TermsAndConditions,
  Thankyou,
  Tracker,
  Tracking,
  UserProfile,
  Welcome,
  Wrap,
} from "./PageAsync";
// ==============================|| MAIN ROUTING ||============================== //

interface MainAppRoutesProps {
  classes?: any;
}

export function MainAppRoutes(props: MainAppRoutesProps) {
  const { classes } = props;
  const { isMobile } = useUserSettings();
  const MainRoutes = {
    path: "/",
    element: <MainLayout classes={classes} />,
    children: [
      {
        path: "/",
        element: <Home classes={classes} />,
      },
      {
        path: "/welcome",
        element: isMobile ? (
          <Login classes={classes} />
        ) : (
          <Welcome classes={classes} />
        ),
      },
      {
        path: "/login",
        element: <Login classes={classes} />,
      },

      {
        path: "/phone-verify",
        element: <PhoneVerify classes={classes} />,
      },
      {
        path: "/service-method",
        element: <ServiceMethod classes={classes} />,
      },
      {
        path: "/otp-verify",
        element: <OTPVerification classes={classes} />,
      },
      {
        path: "/signup-details",
        element: <SignUpDetails classes={classes} />,
      },
      {
        path: "/addresses",
        element: <Addressess classes={classes} />,
      },
      {
        path: "/add-address/:id?",
        element: <AddAddress classes={classes} />,
      },
      {
        path: "/address-details/:id?",
        element: <AddressDetails classes={classes} />,
      },
      {
        path: "/choose-store",
        element: <ChooseStore classes={classes} />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword classes={classes} />,
      },

      // categories start
      {
        path: "/offers",
        element: <Offers classes={classes} />,
      },
      {
        path: "/offer/details/:id",
        element: <OfferDetail classes={classes} />,
      },
      {
        path: "/pizza",
        element: <Pizza classes={classes} />,
      },
      {
        path: "/pizzadetails/:subcategory/:id",
        element: <PizzaDetails classes={classes} />,
      },
      {
        path: "/bread",
        element: <Bread classes={classes} />,
      },
      {
        path: "/chicken",
        element: <Chicken classes={classes} />,
      },
      {
        path: "/wrap",
        element: <Wrap classes={classes} />,
      },
      {
        path: "/sandwich",
        element: <Sandwich classes={classes} />,
      },
      {
        path: "/loadpotato",
        element: <LoadPotato classes={classes} />,
      },
      {
        path: "/dessert",
        element: <Desserts classes={classes} />,
      },
      {
        path: "/drinks",
        element: <Drinks classes={classes} />,
      },
      {
        path: "/sauce",
        element: <Sauce classes={classes} />,
      },
      // categories end

      {
        path: "/stores",
        element: <Stores classes={classes} />,
      },
      {
        path: "/cart/:order_id?",
        element: <Cart classes={classes} />,
      },
      {
        path: "/order-details",
        element: <OrderDetail classes={classes} />,
      },
      {
        path: "/add-card",
        element: <AddCard classes={classes} />,
      },
      {
        path: "/order-confirmation",
        element: <OrderConfirmation classes={classes} />,
      },
      {
        path: "/order-history",
        element: <OrderHistory classes={classes} />,
      },
      {
        path: "/user-profile",
        element: <UserProfile classes={classes} />,
      },
      {
        path: "/Rewards",
        element: <Rewards classes={classes} />,
      },
      {
        path: "/thankyou",
        element: <Thankyou classes={classes} />,
      },
      {
        path: "/tracking",
        element: <Tracking classes={classes} />,
      },
      {
        path: "/track-my-order/:id",
        element: <Tracker classes={classes} />,
      },
      {
        path: "/favorites",
        element: <Favorite classes={classes} />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditions classes={classes} />,
      },
      {
        path: "/delivery-policy",
        element: <DeliveryPolicy classes={classes} />,
      },
      {
        path: "/privacy-policy",
        element: <PrivacyPolicy classes={classes} />,
      },
      {
        path: "/return-policy",
        element: <ReturnPolicy classes={classes} />,
      },
      {
        path: "/about",
        element: <About classes={classes} />,
      },
      {
        path: "/hygiene-certificate",
        element: <HygieneCertificate classes={classes} />,
      },
      {
        path: "/order-details/:id",
        element: <SingleOrderDetails classes={classes} />,
      },
      {
        path: "/feedback/:id",
        element: <Feedback classes={classes} />,
      },
      {
        path: "/join-our-team",
        element: <JoinOurTeam classes={classes} />,
      },
      {
        path: "/join-our-team/:job_title",
        element: <JoinUsForm classes={classes} />,
      },
      {
        path: "/half-half-details",
        element: <HalfnHalfDetails classes={classes} />,
      },
      {
        path: "/half-half-details/:subcategory/:id",
        element: <HalfnHalfDetails classes={classes} />,
      },
      {
        path: "/*",
        element: <NotFound classes={classes} />,
      },
    ],
  };
  return MainRoutes;
}
